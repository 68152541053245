import useFetchGet from "../../hooks/useFetchGet";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef } from "react";
import { useNavigate } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faChevronDown,
    faUserShield,
    faUser,
    faHouse,
    faXmark,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import SelectClients from "./selectClients";
import SelectTyC from "../inputs/SelectTyC";

function QuoteForm(props) {
    const { row } = props;
    const [isActive, setIsActive] = useState(false);
    const [clientSelected, setClientSelected] = useState();
    const [counter2, setCounter2] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [sending, setSending] = useState(false);
    const navigate = useNavigate();

    //{"position": 0, "quantity": 0, "units": "", "service": "", "description": "", "unitPrice": 0.0, "unitTotal": 0.0}
    const layoutRef= useRef({});
    layoutRef.current = counter2;
    console.log("currentarray ", counter2);

    const changeTab = index => {
		console.log(index);
		setClientSelected(index);
	};

    const handleClick = () => {
        setCounter2(prevState => prevState + 1 );

        //setCounter2(counter2 + 1);
        console.log(counter2);
    };

    const handleDelete = (e) => {
        const index = e.currentTarget.dataset.index
        console.log(index);
        console.log("oldarray ", counter2);
        
        //setCounter2(counter2 => [...counter2,1] );
        setCounter2(prevState => prevState - 1)
        //setCounter2(counter2 + 1);
        console.log("newarray ", counter2);
    };

    const handleTotal = (e) => {
        const index = e.target ? e.target.dataset.index : null 
        console.log(index);

        var quantity = parseFloat(document.getElementById("quantity" + index).value).toFixed(2);
        var unitPrice = parseFloat(document.getElementById("unitPrice" + index).value).toFixed(2);
        //var totalPrice = document.getElementById("totalPrice" + index).value;
        var total = quantity * unitPrice

        document.getElementById("totalPrice" + index).value = parseFloat(total).toFixed(2)
        console.log("total: ", total);
        
        calculateTotal()
        
    };

    function calculateTotal () {
        var total = 0
        Array.from(Array(counter2), (e, index) => {
            var totalPrice = parseFloat(document.getElementById("totalPrice" + index).value).toFixed(2);
            total += totalPrice
            
        })

        console.log("total: ", total);
        

        document.getElementById("total").value = parseFloat(total).toFixed(2)
    }

    const handleEventForm = async event => {
        const ccToRequisitor = event.target.ccToRequisitor.checked;
        event.preventDefault();
        console.log(ccToRequisitor);

        if (clientSelected == null) {
            toast.warning("Select Client");
        } else {
        
        setSending(true)

        console.log(event.target);
        
        const clientId = clientSelected.id;
        const clientName = clientSelected.value;
		const rfc = event.target.rfc.value;
        const calle = event.target.calle.value;
        const colonia = event.target.colonia.value;
        const cp = event.target.cp.value;
        const ciudad = event.target.ciudad.value;
        const estado = event.target.estado.value;
        const pais = event.target.pais.value;

        const requisitor = event.target.requisitor.value;
        const correo = event.target.correo.value;
        const fse = event.target.fse.value; //esto no se guarda pero se envia, no se ni para que se use
        const tyc = event.target.tyc.value;

        const total = event.target.total.value;

        var services = []

        Array.from(Array(counter2), (e, index) => {
            console.log(index);
            //var x = "quantity" + index;
            //console.log(document.getElementById(x).value);
            
            const quantity = document.getElementById("quantity" + index).value;
            const units = document.getElementById("units" + index).value;
            const service = document.getElementById("service" + index).value;
            const description = document.getElementById("description" + index).value;
            const unitPrice = document.getElementById("unitPrice" + index).value;
            const totalPrice = document.getElementById("totalPrice" + index).value;

            services.push(
                {
                    quantity: quantity,
                    units: units,
                    service:service,
                    description: description,
                    unitPrice: unitPrice,
                    totalPrice: totalPrice
                }
            )
            console.log("quantity" + index + ": " + quantity);
            
            
        })

        console.log(services);

            try {
                const endpoint = process.env.REACT_APP_API_URL + "quotes";
                const response = await axios.post(
                    endpoint,
                    {
                        clientId: clientId,
                        poId: 0,
                        requisitor: requisitor,
                        fse: fse,
                        requisitorMail: correo,
                        ccToRequisitor: ccToRequisitor,
                        tycId: parseInt(tyc),
                        total: total,
    
                        clientName: clientName,
                        rfc: rfc,
                        calle: calle,
                        colonia: colonia,
                        cp: cp,
                        ciudad: ciudad,
                        estado: estado,
                        pais: pais,
    
                        services: services,
    
                        userId: localStorage.getItem("userId"),
                        userEmail: localStorage.getItem("userEmail"),
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
    
                console.log(response.data);
                console.log(true);
                toast.success(response.data.message);
                navigate(`/quotes`);
                //setRefresh(!refresh);
            } catch (error) {
                console.log(error);
                toast.error(error);
            }
        }
    }

    return (
        <div className="min-h-[90vh]">
            <form onSubmit={handleEventForm}>
            <div class="p-4 flex justify-between">
                <h2 class="card-title">Create Quote</h2>

                <div className="">
                    {sending ? 
                        <button class="btn btn-sm btn-info" disabled="true">
                            <span class="loading loading-spinner"></span>
                            Creating Quote
                        </button>
                    :
                        <button class="btn btn-sm btn-info px-6">
                            <FontAwesomeIcon icon={faPlus} />
                            Create Quote
                        </button>
                    }

                </div>

            </div>
            <div class="divider mt-10"></div>


                <div className="border-1 grid grid-cols-6 gap-4 p-2">
                    <div className="form-control w-full hidden">
                        <label className="label">
                            <span className="label-text">Id</span>
                        </label>
                        <input
                            type="text"
                            className="input input-bordered w-full"
                            name="id"
                            id="id"
                            disabled
                            defaultValue={clientSelected ? clientSelected.id : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Select Customer</span>
                        </label>

                        <SelectClients changeTab={changeTab}></SelectClients>
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">RFC</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="rfc"
                            id="rfc"
                            defaultValue={clientSelected ? clientSelected.rfc : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Calle</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="calle"
                            id="calle"
                            defaultValue={clientSelected ? clientSelected.calle : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Colonia</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="colonia"
                            id="colonia"
                            defaultValue={clientSelected ? clientSelected.colonia : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Codigo Postal</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="cp"
                            id="cp"
                            defaultValue={clientSelected ? clientSelected.cp : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Ciudad</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="ciudad"
                            id="ciudad"
                            defaultValue={clientSelected ? clientSelected.ciudad : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Estado</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="estado"
                            id="estado"
                            defaultValue={clientSelected ? clientSelected.estado : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Pais</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="pais"
                            id="pais"
                            defaultValue={clientSelected ? clientSelected.pais : ""}
                        />
                    </div>

                

                </div>

                <div class="divider"></div>

                <div className="border-1 grid grid-cols-4 gap-4 p-2">
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Requisitor</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="requisitor"
                            id="requisitor"
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Correo</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="correo"
                            id="correo"
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">FSE</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name="fse"
                            id="fse"
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">TyC</span>
                        </label>
                        <SelectTyC></SelectTyC>
                    </div>
                </div>

                <div class="form-control ">
                    <label class="label cursor-pointer flex justify-end">
                        <span class="label-text">Send copy to Requisitor</span>
                        <input type="checkbox" id="ccToRequisitor" class="checkbox checkbox-sm ml-2" />
                    </label>
                </div>

                <div class="divider"></div>

                <div className="w-full flex justify-end px-2">
                    <button type="button" class="btn btn-sm btn-outline px-6 mr-6" onClick={handleDelete}>
                        <FontAwesomeIcon icon={faXmark} />
                        Remove Field
                    </button>
                    <button type="button" class="btn btn-sm btn-outline px-6" onClick={handleClick}>
                        <FontAwesomeIcon icon={faPlus} />
                        Add Field
                    </button>
            </div>

                <div>
                {Array.from(Array(counter2), (e, index) => {
                    return (<div className="border-1 grid grid-cols-12 gap-4 p-2">
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Quantity {index} </span>
                        </label>
                        <input
                            type="number"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name={"quantity" + index}
                            id={"quantity" + index}
                            min="0"
                            step="any"
                            onChange={handleTotal}
                            data-index={index}
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Units</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name={"units" + index}
                            id={"units" + index}
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control col-span-4">
                        <label className="label">
                            <span className="label-text">Service</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name={"service" + index}
                            id={"service" + index}
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control col-span-4">
                        <label className="label">
                            <span className="label-text">Description</span>
                        </label>
                        <input
                            type="text"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name={"description" + index}
                            id={"description" + index}
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Unit Price</span>
                        </label>
                        <input
                            type="number"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name={"unitPrice" + index}
                            id={"unitPrice" + index}
                            min="0"
                            step=".01"
                            onChange={handleTotal}
                            data-index={index}
                            defaultValue="0"
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Total Price</span>
                        </label>
                        <input
                            type="number"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            min="0"
                            step=".01"
                            name={"totalPrice" + index}
                            id={"totalPrice" + index}
                            defaultValue="0"
                        />
                    </div>

                    <FontAwesomeIcon icon={faPlus} className="pointer hidden" data-index={index} onClick={handleDelete}/>
                </div>);
                })}

                <div className="border-1 grid grid-cols-12 gap-4 p-2 flex items-end">
                    <div className="form-control col-start-12">
                        <label className="label">
                            <span className="label-text">TOTAL</span>
                        </label>
                        <input
                            type="number"
                            min="0"
                            step=".01"
                            className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none"
                            name={"total"}
                            id={"total"}
                            //defaultValue={row ? row.id : ""}
                        />
                    </div>
                    </div>

                </div>


            </form>


        </div>
    );
}

export default QuoteForm;
